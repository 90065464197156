import React, { useEffect, useState } from 'react';
import { Box, Typography } from '@mui/material';
import { Line } from 'react-chartjs-2';
import 'chart.js/auto';
import apiClient from "../../helpers/axiosHelper";

const StatsCard = ({ title, value, percentage, chartData, percentageColor }) => {
    const options = {
        elements: {
            point: { radius: 0 },
        },
        scales: {
            x: { display: false },
            y: { display: false },
        },
        plugins: {
            legend: { display: false },
        },
    };

    return (
        <Box
            sx={{
                flex: '1 1 250px', // Allows the card to grow and shrink
                maxWidth: '300px', // Sets a maximum width
                height: 120,
                bgcolor: '#00aab70f',
                borderRadius: 2,
                p: 2,
                boxShadow: 3,
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'space-between',
                position: 'relative',
                overflow: 'hidden',
            }}
        >
            <Box sx={{ zIndex: 1, textAlign: 'center' }}>
                <Typography variant="body2" color="textSecondary">
                    {title}
                </Typography>
                <Typography variant="h4">{value}</Typography>
                <Typography variant="body2" sx={{ color: percentageColor, fontWeight: 'bold' }}>
                    {percentage > 0 ? `▲ ${percentage}%` : `▼ ${percentage}%`}
                </Typography>
            </Box>
            <Box
                sx={{
                    position: 'absolute',
                    bottom: 0,
                    left: 0,
                    right: 0,
                    height: '60px',
                    zIndex: 0,
                    padding: 0,
                }}
                className="line-container"
            >
                <Line data={chartData} options={options} />
            </Box>
        </Box>
    );
};

export default function Dashboard() {
    const [loading, setLoading] = useState(false);
    const [totalCalls, setTotalCalls] = useState(0);
    const [totalInboundCalls, setInboundTotalCalls] = useState(0);
    const [totalOutboundCalls, setOutboundTotalCalls] = useState(0);
    const [totalCallsStats, setTotalCallsStats] = useState([]);
    const [totalInboundCallsStats, setInboundTotalCallsStats] = useState([]);
    const [totalOutboundCallsStats, setOutboundTotalCallsStats] = useState([]);

    useEffect(() => {
        syncCallsStatistics();
    }, []);

    const syncCallsStatistics = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/aws/calls-statistics`);
            const { success, data } = response;
            if (success) {
                setTotalCalls(data.total_calls || 0);
                setInboundTotalCalls(data.total_inbound || 0);
                setOutboundTotalCalls(data.total_outbound || 0);

                setTotalCallsStats(data.total_calls_stats || []);
                setInboundTotalCallsStats(data.total_inbound_stats || []);
                setOutboundTotalCallsStats(data.total_outbound_stats || []);
                console.log('data: ', data);
            }
        } catch (e) {
            console.error(e.message);
        }
        setLoading(false);
    };

    const postsData = {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
            {
                data: totalCallsStats,
                borderColor: '#00bcd4',
                fill: true,
                backgroundColor: 'rgba(0, 188, 212, 0.1)',
                tension: 0.4,
            },
        ],
    };

    const pagesData = {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
            {
                data: totalInboundCallsStats,
                borderColor: '#00D084',
                fill: true,
                backgroundColor: 'rgba(0, 208, 132, 0.1)',
                tension: 0.4,
            },
        ],
    };

    const commentsData = {
        labels: ['', '', '', '', '', '', ''],
        datasets: [
            {
                data: totalOutboundCallsStats,
                borderColor: '#FFB300',
                fill: true,
                backgroundColor: 'rgba(255, 179, 0, 0.1)',
                tension: 0.4,
            },
        ],
    };

    const calculatePercentageChange = (current, previous) => {
        if (previous === 0) {
            return current > 0 ? 100 : current < 0 ? -100 : 0;
        }
        return ((current - previous) / Math.abs(previous)) * 100;
    };

    const totalCallsLast = totalCallsStats[totalCallsStats.length - 1];
    const totalCallsSecondLast = totalCallsStats[totalCallsStats.length - 2];
    const totalInboundLast = totalInboundCallsStats[totalInboundCallsStats.length - 1];
    const totalInboundSecondLast = totalInboundCallsStats[totalInboundCallsStats.length - 2];
    const totalOutboundLast = totalOutboundCallsStats[totalOutboundCallsStats.length - 1];
    const totalOutboundSecondLast = totalOutboundCallsStats[totalOutboundCallsStats.length - 2];

    const pctChangeTotalCalls = calculatePercentageChange(totalCallsLast, totalCallsSecondLast);
    const pctChangeTotalInbound = calculatePercentageChange(totalInboundLast, totalInboundSecondLast);
    const pctChangeTotalOutbound = calculatePercentageChange(totalOutboundLast, totalOutboundSecondLast);

    return (
        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 3 }}>
            <StatsCard
                title="CALLS"
                value={totalCalls}
                percentage={pctChangeTotalCalls}
                percentageColor={pctChangeTotalCalls > 0 ? "#4caf50" : "#f44336"}
                chartData={postsData}
            />
            <StatsCard
                title="INBOUND"
                value={totalInboundCalls}
                percentage={pctChangeTotalInbound}
                percentageColor={pctChangeTotalInbound > 0 ? "#4caf50" : "#f44336"}
                chartData={pagesData}
            />
            <StatsCard
                title="OUTBOUND"
                value={totalOutboundCalls}
                percentage={pctChangeTotalOutbound}
                percentageColor={pctChangeTotalOutbound > 0 ? "#4caf50" : "#f44336"}
                chartData={commentsData}
            />
        </Box>
    );
}