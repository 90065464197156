import axios from 'axios';

const apiClient = axios.create({
    baseURL: process.env.REACT_APP_API_URL
});

apiClient.interceptors.request.use(
    (config) => {
        const token = localStorage.getItem('authToken');
        if (token) {
            config.headers.Authorization = `Bearer ${token}`;
        }
        return config;
    },
    (error) => {
        return Promise.reject({ success: false, message: error?.message || "Server error" });
    }
);

apiClient.interceptors.response.use(
    (response) => {
        return response.data;
    },
    (error) => {
        console.log('AXIOS REQUEST ERROR: ', error);
        return Promise.reject({ success: false, message: error?.response?.data?.message || error?.message || "Server error" });
    }
);

export default apiClient;
