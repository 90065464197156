import React, { useState } from 'react';
import { Modal, Box, Button, TextField, Typography } from '@mui/material';

const TextAreaModal = ({notesModalOpen, setNotesModalOpen, textValue, handleChange}) => {
    const [open, setOpen] = useState(false);
    console.log('notesModalOpen: ', notesModalOpen);
    return (
        <div>
            <Modal
                open={notesModalOpen}
                aria-labelledby="text-area-modal-title"
                aria-describedby="text-area-modal-description"
                onClose={()=>{setNotesModalOpen(false)}}
            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 400,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 3,
                    borderRadius: 2,
                }}>
                    <Typography id="text-area-modal-title" variant="h6" component="h2">
                        Enter your notes here
                    </Typography>
                    <TextField
                        id="notes-text-area"
                        label="Notes..."
                        multiline
                        rows={4}
                        variant="outlined"
                        fullWidth
                        sx={{ marginTop: 2 }}
                        value={textValue}
                        onChange={handleChange}
                    />
                </Box>
            </Modal>
        </div>
    );
};

export default TextAreaModal;
