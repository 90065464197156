import React, { useState, useEffect } from 'react';
import {Table, TableBody, TableRow, TableCell, Loader, Label, Checkbox, Icon, Button} from "monday-ui-react-core";
import { AddUpdate } from "monday-ui-react-core/icons";
import apiClient from "../../helpers/axiosHelper";
import Modal from '@mui/material/Modal';
import {Box, Typography} from "@mui/material";

const Outbound = ({ setStatusBase }) => {
    const [outboundCalls, setOutboundCalls] = useState([]);
    const [loading, setLoading] = useState(false);
    const [page, setPage] = useState(1);
    const [total, setTotal] = useState(0);
    const [pageSize, setPageSize] = useState(10);
    const [open, setOpen] = useState(false);
    const [notes, setNotes] = useState('');
    const [htmlContent, setHtmlContent] = useState('');

    const handleOpen = () => setOpen(true);
    const handleClose = () => setOpen(false);

    useEffect(() => {
        syncOutboundCalls();
    }, [page, pageSize]);

    const syncOutboundCalls = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get(`/aws/get-outbound-calls?page=${page}&limit=${pageSize}`);
            const { success, data, total } = response;
            if (success) {
                setOutboundCalls(Array.isArray(data) ? data : []);
                setTotal(total);
            }
        } catch (e) {
            setStatusBase({ msg: e.message, key: Math.random(), severity: "error" });
            setOutboundCalls([]);
        }
        setLoading(false);
    };

    const handlePageChange = (newPage) => {
        setPage(newPage);
    };

    const cellStyles = {
        checkbox: { width: '50px', maxWidth: '50px', minWidth: '50px', padding: '8px' },
        phoneNumber: { width: '23%' },
        type: { width: '18%' },
        duration: { width: '33%' },
        date: { width: '18%' }
    };

    const columns = [
        // { id: 'checkbox', title: '', style: cellStyles.checkbox },
        { id: 'phone_number', title: 'Phone Number', style: cellStyles.phoneNumber },
        { id: 'type', title: 'Type', style: cellStyles.type },
        {
            id: 'duration',
            title: 'Duration',
            style: cellStyles.duration,
            formatter: (value) => {
                try {
                    const duration = JSON.parse(value);
                    return `${duration.hours} hours, ${duration.minutes} minutes, ${duration.seconds} seconds.`;
                } catch (e) {
                    return value || '-';
                }
            }
        },
        { id: 'date', title: 'Date', style: cellStyles.date }
    ];

    const rowStyle = {
        position: 'relative',
    };

    const borderStyle = {
        content: '""',
        position: 'absolute',
        left: 0,
        top: 0,
        bottom: 0,
        width: '4px',
        backgroundColor: '#00ca72',
    };

    return (
        <div style={{ width: '100%', height: '100%' }}>
            <h4>Outbound Details</h4>
            <Table
                columns={columns}
                dataState={{ isLoading: loading }}
                style={{
                    width: '100%',
                    tableLayout: 'fixed',
                    '--table-grid-template-columns': '' +
                        // 'minmax(50px, 50px) ' +
                        'minmax(200px, 1fr) minmax(150px, 0.8fr) minmax(200px, 1fr) minmax(220px, 1fr)'
                }}
            >
                <TableBody>

                    <TableRow >
                        {/*<TableCell*/}
                        {/*    style={{*/}
                        {/*        ...cellStyles.checkbox,*/}
                        {/*        backgroundColor: '#fff',*/}
                        {/*        textAlign: 'center'*/}
                        {/*    }}*/}
                        {/*    className="table-column"*/}
                        {/*>*/}
                        {/*</TableCell>*/}
                        {columns.slice(0).map(column => (
                            <TableCell
                                key={column.id}
                                title={column.title}
                                style={{
                                    ...column.style,
                                    whiteSpace: 'nowrap',
                                    overflow: 'hidden',
                                    textOverflow: 'ellipsis',
                                    backgroundColor: '#fff',
                                    justifyContent: 'center'
                                }}
                                className="table-column justify-center"
                            >
                                {column.title}
                            </TableCell>
                        ))}
                    </TableRow>
                    {outboundCalls.length > 0 ? (
                        outboundCalls.map((call, index) => (
                            <TableRow
                                key={index}
                                style={{
                                    ...rowStyle,
                                    '&:hover': {
                                        backgroundColor: '#f5f5f5'
                                    }
                                }}
                            >
                                <div style={borderStyle} />
                                {/*<TableCell*/}
                                {/*    style={{*/}
                                {/*        ...cellStyles.checkbox,*/}
                                {/*        borderBottom: '1px solid #e0e0e0',*/}
                                {/*    }}*/}
                                {/*    className="table-column"*/}
                                {/*>*/}
                                {/*    <div style={{ display: 'flex', justifyContent: 'center' }}>*/}
                                {/*        <Checkbox kind="primary" size="small" />*/}
                                {/*    </div>*/}
                                {/*</TableCell>*/}
                                {columns.slice(0).map(column => (
                                    <TableCell
                                        key={column.id}
                                        style={{
                                            ...column.style,
                                            whiteSpace: 'nowrap',
                                            overflow: 'hidden',
                                            textOverflow: 'ellipsis',
                                            padding: '8px 16px',
                                            borderBottom: '1px solid #e0e0e0',
                                        }}
                                        className={column.id !== 'date' ? 'table-column' : ''}
                                    >
                                        {column.id === 'phone_number' ? (
                                            <div style={{
                                                display: 'flex',
                                                justifyContent: 'space-between',
                                                alignItems: 'center',
                                                width: '100%'
                                            }}>
                                                <span style={{ flex: 1 }}>{call[column.id] || '-'}</span>
                                                <div style={{
                                                    width: "45px",
                                                    height: "40px",
                                                    borderLeft: '1px solid #e0e0e0',
                                                    marginRight: '-15px',
                                                    textAlign: 'center'
                                                }}>
                                                    <Icon
                                                        onClick={()=>{
                                                            console.log('column: ', call);
                                                            handleOpen();
                                                            setNotes(call.notes ? call.notes.replace(/\\n/g, '\n') : "")
                                                            setHtmlContent(call.transcriptValue)
                                                        }}
                                                        icon={AddUpdate}
                                                        iconSize={25}
                                                        iconLabel="Add"
                                                        style={{
                                                            color: '#676879',
                                                            marginTop: '8px'
                                                        }}
                                                        className="button-hover"
                                                    />
                                                </div>
                                            </div>
                                        ) : column.id === 'type' ? (
                                            <Label
                                                isAnimationDisabled
                                                text={call[column.id] || '-'}
                                            />
                                        ) : column.formatter ? (
                                            column.formatter(call[column.id])
                                        ) : (
                                            call[column.id] || '-'
                                        )}
                                    </TableCell>
                                ))}
                            </TableRow>
                        ))
                    ) : (
                        <TableRow>
                            <TableCell colSpan={columns.length} style={{ textAlign: 'center' }}>
                                No data available
                            </TableCell>
                        </TableRow>
                    )}
                </TableBody>
            </Table>

            {/*{loading && (*/}
            {/*    <div style={{*/}
            {/*        position: 'absolute',*/}
            {/*        top: '50%',*/}
            {/*        left: '50%',*/}
            {/*        transform: 'translate(-50%, -50%)',*/}
            {/*        zIndex: 2*/}
            {/*    }}>*/}
            {/*        <Loader size={Loader.sizes.MEDIUM} />*/}
            {/*    </div>*/}
            {/*)}*/}

            <div style={{
                padding: '16px',
                display: 'flex',
                justifyContent: 'space-between',
                borderTop: '1px solid #e0e0e0',
                paddingLeft: 0,
                paddingRight: 0
            }}>
                <div>Showing {(page - 1) * pageSize + 1} to {Math.min(page * pageSize, total)} of {total} entries</div>
                <div style={{display: 'flex', gap: '8px'}}>
                    <Button kind={Button.kinds.SECONDARY} onClick={() => handlePageChange(page - 1)}
                            disabled={page === 1}>Previous</Button>
                    <Button onClick={() => handlePageChange(page + 1)} disabled={page * pageSize >= total}>Next</Button>
                </div>
            </div>

            <Modal
                open={open}
                onClose={handleClose}
                aria-labelledby="modal-modal-title"
                aria-describedby="modal-modal-description"

            >
                <Box sx={{
                    position: 'absolute',
                    top: '50%',
                    left: '50%',
                    transform: 'translate(-50%, -50%)',
                    width: 700,
                    bgcolor: 'background.paper',
                    boxShadow: 24,
                    p: 2,
                    maxHeight: '80vh',
                    overflowY: 'auto',
                    ...(htmlContent.includes('No transcript available.') ? { } : {
                        display: 'flex'
                    })

                }}>
                    <div id="modal-modal-description" dangerouslySetInnerHTML={{__html: htmlContent}}/>
                    <div style={{
                        paddingTop: '20px',
                        ...(htmlContent.includes('No transcript available.') ? { marginRight: '30px' } : {

                        })

                    }}>
                        <h2 style={{textAlign: 'center', color: '#333'}} className="transcript-title">Notes</h2>
                        <div
                            style={{
                                backgroundColor: '#ffffff',
                                borderRadius: '8px',
                                boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                                padding: '20px',
                                ...(htmlContent.includes('No transcript available.') ? {marginTop: '20px'} : {
                                    marginTop: '58px',
                                    width: '90%'
                                }),
                            }}
                        >
                            <Typography
                                component="div"
                                sx={{
                                    whiteSpace: 'pre-line', // Converts \n into line breaks
                                    overflowWrap: 'break-word'
                                }}
                            >
                                {notes || 'No Notes'}
                            </Typography>
                        </div>
                    </div>

                </Box>
            </Modal>
        </div>
    );
};

export default Outbound;
