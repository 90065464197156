import React, {useState, useEffect} from "react";
import "./App.css";
import {IconButton, Box, List, ListItem, ListItemIcon, ListItemText, Tooltip} from "@mui/material";
import {Button, Heading, Icon} from "monday-ui-react-core";
import { LogOut } from "monday-ui-react-core/icons";
import {
    Menu as MenuIcon,
    ChevronLeft as ChevronLeftIcon,
    CallReceived as CallReceivedIcon,
    CallMade as CallMadeIcon,
} from '@mui/icons-material';
import apiClient from "./helpers/axiosHelper";
import Config from "./modules/config";
import "monday-ui-react-core/dist/main.css";
import AWSLogo from "./assets/images/amazon-connect.svg";
import CallNotification from "./modules/calls/CallNotification";
import CallControls from "./modules/calls/CallControls";
import AlertMessage from "./modules/Alert";
import Inbound from "./modules/calls/Inbound";
import Outbound from "./modules/calls/Outbound";
import Dashboard from "./modules/Dashboard";
import ConnectClient from "./helpers/ConnectClient";
import "monday-ui-react-core/dist/main.css";
import LogoutIcon from "@mui/icons-material/Logout";
import TextAreaModal from "./modules/Notes";

const App = () => {
    const AWS_CONNECT = window.connect;
    const [pageLoading, setPageLoading] = useState(false);
    const [loading, setLoading] = useState(false);
    const [userKey, setUserKey] = useState(null);
    const [status, setStatusBase] = useState({msg: '', key: 0, severity: "error"});
    const [awsConfig, setAWSConfig] = useState({
        region: '',
        accessKeyId: '',
        secretAccessKey: '',
        connectInstanceAlias: '',
        customerProfileDomain: '',
        instanceId: '',
    });
    const [initializing, setInitializing] = useState(false);
    const [connectClient, setConnectClient] = useState(null);
    const [loggedIn, setLoggedIn] = useState(false);
    const [selectedIndex, setSelectedIndex] = useState(1);
    const [isSidebarOpen, setIsSidebarOpen] = useState(true);
    const [haveErrors, setHaveErrors] = useState(true);
    const [notesModalOpen, setNotesModalOpen] = useState(false);
    const [notesValue, setNotesValue] = useState('');

    const handleNotesChange = (event) => {
        setNotesValue(event.target.value);
        localStorage.setItem('notes-value', event.target.value);
    };

    console.log('notesValue: ', notesValue);

    useEffect(() => {
        console.log('awsConfig: ', awsConfig);
        const {statusInfo} = awsConfig;
        if (statusInfo)
            setHaveErrors(Object.values(statusInfo).some(status => !status));
    }, [awsConfig])

    useEffect(() => {
        const token = localStorage.getItem('authToken');
        if (token)
            fetchConfig();
        handleAuthToken();
    }, []);

    const fetchConfig = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get('/aws/get-config');
            if (response.success) {
                setAWSConfig(response.data);
            }
        } catch (e) {
            setStatusBase({msg: e.message, key: Math.random(), severity: "error"});
            forceLogout();
        }
        setLoading(false);
    };

    const handleAuthToken = () => {
        const urlParams = new URLSearchParams(window.location.search);
        const tokenParam = urlParams.get('token');
        if (tokenParam) {
            setUserKey(tokenParam);
            localStorage.setItem('authToken', tokenParam);
            urlParams.delete('token');
            window.history.replaceState({}, '', `${window.location.pathname}?${urlParams}`);
        } else {
            const authToken = localStorage.getItem('authToken');
            if (authToken) {
                setUserKey(authToken);
            }
        }
    };

    const requestOAuth = async () => {
        setLoading(true);
        try {
            const response = await apiClient.get('/oauth');
            if (response.success && response.url) {
                window.location.href = response.url;
            }
        } finally {
            // setLoading(false);
        }
    };

    const initializeConnectWithQ = async (newConfig = awsConfig) => {
        setInitializing(true);
        const {statusInfo} = newConfig;
        const haveErrors = Object.values(statusInfo).some(status => !status);

        if (haveErrors) {
            const failedServices = [];
            if (!statusInfo.connectStatus) failedServices.push('CONNECT');
            if (!statusInfo.contactLensStatus) failedServices.push('CONTACT LENS');
            if (!statusInfo.customerProfileStatus) failedServices.push('CUSTOMER PROFILE');
            if (!statusInfo.ccpURL) failedServices.push('CONNECT INSTANCE ALIAS');

            setStatusBase({
                msg: `VALIDATION FAILED: ${failedServices.join(', ')}`,
                key: Math.random(),
                severity: "error"
            });
            setInitializing(false);
            return;
        } else {
            // setStatusBase({
            //     msg: `VALIDATION PASSED!`,
            //     key: Math.random(),
            //     severity: "success"
            // });
        }

        if (AWS_CONNECT) {
            const client = new ConnectClient(newConfig, setNotesValue);
            console.log('initializing...');
            client.init((callback) => {
                setConnectClient(client);
                setInitializing(false);
                setLoggedIn(true);
            });
        }
    };

    const toggleSidebar = () => setIsSidebarOpen(!isSidebarOpen);

    const login = async () => {
        await initializeConnectWithQ();
    };

    const logout = () => {
        window.open(`https://${awsConfig.connectInstanceAlias}.my.connect.aws/logout`, '_blank').focus();
        window.location.reload();
    };

    const isFormValid = () => {
        const requiredFields = ['region', 'accessKeyId', 'secretAccessKey', 'connectInstanceAlias', 'customerProfileDomain', 'instanceId'];
        return requiredFields.every(field => awsConfig[field]?.trim());
    };

    console.log('haveErrors: ', haveErrors);

    const forceLogout = () => {
        window.localStorage.clear()
        localStorage.clear()
        window.location.reload()
    }
    console.log('notesValue: ', notesValue)
    return (
        <div className="App">
            <button style={{display: "none"}} onClick={forceLogout}>logout</button>
            <Box id="call-notification-ui" sx={{
                display: 'none',
                position: 'fixed',
                top: '2%',
                left: '55%',
                transform: 'translateX(-50%)',
                zIndex: 1000
            }}>
                <CallNotification toggleNotes={setNotesModalOpen}/>
            </Box>
            <div id="ccp" style={{
                display: "none",
                width: "50%",
                position: "absolute",
                float: 'left',
                height: '100vh',
                marginLeft: '170px'
            }}/>
            {pageLoading ? (
                <div>Loading...</div>
            ) : (
                userKey ? (
                   <>
                   {
                       loading ? (
                           <div className="full-width" style={{display: "flex", margin: "auto"}}>
                               <div className="lds-roller" style={{margin: "auto"}}>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                                   <div></div>
                               </div>
                           </div>
                       ) : (
                           <div className="full-width" style={{display: "flex", margin: (haveErrors || !loggedIn)  ? "auto" : ""}}>
                               {
                                   haveErrors ? (
                                       <Box sx={{display: 'flex', margin: 'auto'}}>
                                           <Config setStatusBase={setStatusBase} awsConfig={awsConfig}
                                                   setAWSConfig={setAWSConfig}
                                                   initializeConnectWithQ={initializeConnectWithQ}
                                                   initializing={initializing}
                                                   isFormValid={isFormValid} loggedIn={loggedIn}/>
                                       </Box>
                                   ) : (
                                      <>
                                          {loggedIn ? (
                                              <Box className="full-width" sx={{display: 'flex'}}>
                                                  <Box sx={{
                                                      width: isSidebarOpen ? '240px' : '60px',
                                                      backgroundColor: '#ebdef817',
                                                      height: '100vh',
                                                      padding: isSidebarOpen ? 2 : 0,
                                                      position: 'relative',
                                                      transition: 'width 0.6s ease, padding 0.6s ease',
                                                      overflow: 'hidden',
                                                      marginTop: isSidebarOpen ? 0 : '40px',
                                                      display: 'flex',
                                                      flexDirection: 'column',
                                                      justifyContent: 'space-between'
                                                  }}>
                                                      <div>
                                                          {isSidebarOpen && (
                                                              <Box sx={{display: 'flex', alignItems: 'center', marginBottom: 4}}>
                                                                  <img src={AWSLogo} alt="Call Center Logo" style={{width: 150, margin: 'auto'}}/>
                                                              </Box>
                                                          )}
                                                          <List component="nav" aria-label="main mailbox folders" className="custom-nav">
                                                              {[
                                                                  // {index: 0, icon: <HomeIcon/>, label: "Dashboard"},
                                                                  {index: 1, icon: <CallReceivedIcon/>, label: "Inbound"},
                                                                  {index: 2, icon: <CallMadeIcon/>, label: "Outbound"},
                                                              ].map(({index, icon, label}) => (
                                                                  <Tooltip key={index} title={isSidebarOpen ? "" : label} placement="right">
                                                                      <ListItem button selected={selectedIndex === index}
                                                                                onClick={() => setSelectedIndex(index)}
                                                                                sx={selectedIndex === index ? {
                                                                                    backgroundColor: '#fff',
                                                                                    color: '#00aab7',
                                                                                    borderRadius: 2
                                                                                } : null}>
                                                                          <ListItemIcon>{React.cloneElement(icon, {sx: selectedIndex === index ? {color: '#00aab7'} : null})}</ListItemIcon>
                                                                          {isSidebarOpen && <ListItemText primary={label}/>}
                                                                      </ListItem>
                                                                  </Tooltip>
                                                              ))}
                                                          </List>
                                                      </div>

                                                      {/* Logout Button */}
                                                      <Tooltip title={isSidebarOpen ? "" : "Logout"} placement="right" style={{cursor: "pointer"}}>
                                                          <Button style={{background: "#00aab7", marginBottom: '40px'}} onClick={logout}>
                                                              <LogoutIcon sx={{marginTop: '-2px'}}/>
                                                              Logout
                                                          </Button>
                                                      </Tooltip>

                                                      {isSidebarOpen && (
                                                          <IconButton sx={{position: 'absolute', top: 10, right: -20}}
                                                                      onClick={toggleSidebar}>
                                                              <ChevronLeftIcon/>
                                                          </IconButton>
                                                      )}
                                                  </Box>
                                                  <Box sx={{
                                                      width: "60vw",
                                                      flexGrow: 1,
                                                      backgroundColor: '#fff',
                                                      transition: 'margin-left 0.6s ease',
                                                      marginLeft: isSidebarOpen ? 0 : '10px',
                                                  }}>
                                                      {!isSidebarOpen && (
                                                          <IconButton onClick={toggleSidebar}
                                                                      sx={{
                                                                          position: 'absolute',
                                                                          top: 10,
                                                                          left: 10,
                                                                          marginLeft: '-3px'
                                                                      }}>
                                                              <MenuIcon/>
                                                          </IconButton>
                                                      )}
                                                      <div style={{paddingLeft: "10%", paddingRight: "10%", paddingTop: "5%"}}>
                                                          {selectedIndex === 0 && <Dashboard/>}
                                                          {selectedIndex === 1 && <Inbound setStatusBase={setStatusBase}/>}
                                                          {selectedIndex === 2 && (
                                                              <>
                                                                  <CallControls connectClient={connectClient}/>
                                                                  <Outbound setStatusBase={setStatusBase}/>
                                                              </>
                                                          )}
                                                      </div>
                                                  </Box>
                                                  <Box sx={{
                                                      flexGrow: 1,
                                                      backgroundColor: '#fff',
                                                  }}>

                                                      <div id="wisdom-container"
                                                           style={{float: 'right', zIndex: 10}}/>
                                                  </Box>
                                              </Box>
                                          ) : (
                                              <Box sx={{margin: "auto", textAlign: 'center'}}>
                                                  <img src={AWSLogo} alt=""/>
                                                  <br/>
                                                  <Heading type={Heading.types.h2} value="Hello! Please click below to log in and continue" style={{textAlign: "center"}}/>
                                                  <Button style={{width: "250px", fontSize: "25px"}} variant="contained"
                                                               onClick={login}
                                                               disabled={loggedIn || initializing || !isFormValid()}>
                                                      {
                                                          initializing ? 'Logging in...' : 'Login to Connect'
                                                      }
                                              </Button>

                                              </Box>
                                          )}
                                      </>
                                   )
                               }
                           </div>
                       )
                   }
                   </>
                ) : (
                    <Box sx={{margin: "auto", textAlign: 'center'}}>
                        <img src={AWSLogo} alt=""/>
                        <br/>
                        <Button variant="contained" onClick={requestOAuth} style={{width: "250px", fontSize: "25px"}}
                                disabled={loading}>{loading ? 'Requesting OAUTH...' : 'Authenticate'}</Button>
                    </Box>
                )
            )}
            {status.msg && <AlertMessage key={status.key} message={status.msg} severity={status.severity}/>}
            <TextAreaModal notesModalOpen={notesModalOpen} setNotesModalOpen={setNotesModalOpen} textValue={notesValue} handleChange={handleNotesChange}/>
        </div>
    );
};

export default App;