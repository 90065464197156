import React, { useState } from 'react';
import { IconButton, Box, Grid, Popover, Typography } from '@mui/material';
import BackspaceIcon from '@mui/icons-material/Backspace';
import StarIcon from '@mui/icons-material/Star';
import PhoneIcon from '@mui/icons-material/Phone';
import DialpadIcon from '@mui/icons-material/Dialpad';
import SettingsIcon from '@mui/icons-material/Settings';
import {Button} from "monday-ui-react-core";

function CallControls({connectClient}) {
    const [anchorEl, setAnchorEl] = useState(null); // For Popover
    const [phoneNumber, setPhoneNumber] = useState('');
    // const [phoneNumber, setPhoneNumber] = useState('+12133943783');

    // Handle Popover open/close
    const handleClick = (event) => {
        setAnchorEl(anchorEl ? null : event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    // Handle number input
    const handleButtonClick = (value) => {
        setPhoneNumber((prev) => prev + value);
    };

    const handleBackspace = () => {
        setPhoneNumber((prev) => prev.slice(0, -1));
    };

    const handleCall = () => {
        if(connectClient && connectClient.initiateOutboundCall)
            connectClient.initiateOutboundCall(phoneNumber, ()=>{
                handleClose();
            });
    };

    return (
        <Box
            sx={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-around',
                bgcolor: '#2D2F36',
                p: 1,
                borderRadius: 2,
                width: 40,
                marginBottom: "20px"
            }}
        >
            {/*<IconButton sx={{ color: '#fff' }}>*/}
            {/*    <PhoneIcon />*/}
            {/*</IconButton>*/}
            <IconButton sx={{ color: '#fff' }} onClick={handleClick}>
                <DialpadIcon />
            </IconButton>
            {/*<IconButton sx={{ color: '#fff' }}>*/}
            {/*    <SettingsIcon />*/}
            {/*</IconButton>*/}

            {/* Numpad Popover */}
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                PaperProps={{ sx: { borderRadius: '20px', padding: 2 } }}
            >
                <Box
                    sx={{
                        width: 300,
                        textAlign: 'center',
                    }}
                >
                    {/* Displaying Phone Number */}
                    <Typography variant="h4" gutterBottom>
                        {phoneNumber || 'Enter Number'}
                        <IconButton onClick={handleBackspace} style={{position: "absolute", right: "10px"}}>
                            <BackspaceIcon />
                        </IconButton>
                    </Typography>

                    {/* Keypad */}
                    <Grid container spacing={2} justifyContent="center">
                        {[1, 2, 3, 4, 5, 6, 7, 8, 9, '+', 0, '#'].map((key) => (
                            <Grid item xs={4} key={key}>
                                <Button
                                    kind={Button.kinds.SECONDARY}
                                    onClick={() => handleButtonClick(key.toString())}
                                    style={{ width: 60, height: 60, fontSize: '20px' }}
                                >
                                    {key}
                                </Button>
                            </Grid>
                        ))}
                    </Grid>

                    {/* Action Buttons (Star, Call, Backspace) */}
                    <Grid container spacing={2} justifyContent="center" mt={2}>
                        <Grid item>
                            <Button onClick={handleCall}  >
                                <PhoneIcon />
                            </Button>
                        </Grid>
                    </Grid>
                </Box>
            </Popover>
        </Box>
    );
}

export default CallControls;
