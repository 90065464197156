import apiClient from "./axiosHelper";
import dayjs from "dayjs";

import advancedFormat from 'dayjs/plugin/advancedFormat';

dayjs.extend(advancedFormat);

export default class ConnectClient {
    constructor(config, clearNotes) {
        this.AWS_CONNECT = window.connect;
        this.CONFIG = config;
        this.AGENT = null;
        this.CURRENT_CONTACT = null;
        this.CALL_START_TIME = null;
        this.TYPE = "inbound";
        this.CLEAR_NOTES = clearNotes;
    }
    init(onInit) {
        try {
            this.AWS_CONNECT.core.initCCP(document.getElementById('ccp'), {
                ccpUrl: `https://${this.CONFIG.connectInstanceAlias}.my.connect.aws/connect/ccp-v2`,
                region: this.CONFIG.region,
                loginPopup: true,
                loginPopupAutoClose: true,
                softphone: {
                    allowFramedSoftphone: true
                }
            })
            this.AWS_CONNECT.core.onInitialized(() => {

                const self = this;
                setTimeout(() => {
                    console.log('initializing Q')
                    this.AWS_CONNECT.agentApp.initApp(
                        "wisdom",
                        "wisdom-container",
                        `https://${this.CONFIG.connectInstanceAlias}.my.connect.aws/connect` + "/wisdom-v2/",
                        {style: "width:400px; height:600px;"}
                    );
                }, 1000)
                this.AWS_CONNECT.agent((agent) => {
                    self.AGENT = agent;
                    const states = agent.getAgentStates()
                    agent.setState(states.find(item => item.name === "Available"))
                    self.setUpListener();
                    self.setupAnswerButtonEvent();
                    self.setupRejectButtonEvent();
                    this.closeAll();
                });
                if (onInit)
                    onInit();
            });
            // Error callback if authentication fails
            this.AWS_CONNECT.core.onAuthFail(() => {
                console.error("Authentication failed. Check if the ccpUrl is correct and user has permission.");
                alert("Authentication failed. Please check your credentials or ccpUrl.");
                window.location.reload()
            });

            // General error callback
            this.AWS_CONNECT.core.onIframeRetriesExhausted((error) => {
                alert("Error initializing CCP. There might be an invalid configuration or internet connectivity issue.");
                window.location.reload()
            });

            // General error callback
            this.AWS_CONNECT.core.onCTIAuthorizeRetriesExhausted((error) => {
                console.error("Error initializing CCP:", error);
                alert("Error initializing CCP. There might be an invalid configuration or internet connectivity issue.");
                window.location.reload()
            });

            // General error callback
            this.AWS_CONNECT.core.onAuthorizeRetriesExhausted((error) => {
                console.error("Error initializing CCP:", error);
                alert("Error initializing CCP. There might be an invalid configuration or internet connectivity issue.");
                window.location.reload()
            });

            // General error callback
            this.AWS_CONNECT.core.onAccessDenied((error) => {
                console.error("Error initializing CCP:", error);
                alert("Error initializing CCP. There might be an invalid configuration or internet connectivity issue.");
                window.location.reload()
            });

        } catch (error) {
            console.error("An error occurred while initializing the CCP:", error);
            alert("Error initializing CCP. There might be an invalid configuration or internet connectivity issue.");
            window.location.reload()
        }
    }

    setupAnswerButtonEvent() {
        const self = this;
        const answerButton = document.getElementById('accept-call-button')
        if (!answerButton.dataset.listenerAdded) {
            answerButton.addEventListener('click', function () {
                self.answerCall();
            });
            answerButton.dataset.listenerAdded = 'true';
        }
    }

    setupRejectButtonEvent() {
        const self = this;
        const rejectButton = document.getElementById('reject-call-button')
        if (!rejectButton.dataset.listenerAdded) {
            rejectButton.addEventListener('click', function () {
                self.rejectCall();
            });
            rejectButton.dataset.listenerAdded = 'true';
        }
    }

    async setupContactDetails(contact) {
        try {
            console.log(`${this.TYPE} call detected...`)
            console.log(`contact data...: `, contact)
            const customerDetails = contact?.getInitialConnection()?.getAddress();
            if (customerDetails) {
                const name = customerDetails?.name
                const number = customerDetails?.phoneNumber
                document.getElementById("Contact-Name").textContent = name && name || "Unknown"
                document.getElementById("Contact-Avatar").textContent = name && name.charAt(0) || "U"
                document.getElementById("Contact-Number").textContent = number ? ` (${number})` : ""
                const contactId = contact.getContactId();

                // Fetch the contact's ARN
                const contactArn = contact.getContactArn();

                console.log(`Contact ID: ${contactId}`);
                console.log(`Contact ARN: ${contactArn}`);
                await apiClient.post('/monday/firstAddData', {
                    "customerName": name ?? "Unknown",
                    "phoneNumber": number,
                    "type": this.TYPE,
                    "duration": "",
                    "contactId": contact.contactId,
                    "date": dayjs(new Date()).format('MMMM D, YYYY hh:mm A')
                });
            }
        } catch (e) {
            document.getElementById("Contact-Name").textContent = "Unknown"
            document.getElementById("Contact-Avatar").textContent = "U"
        }
    }

    updateStatus(status) {
        switch (status) {
            case "ongoing":
                document.getElementById('call-notification-ui').style.display = 'block'
                document.getElementById('accept-call-button').textContent = "Call Ongoing..."
                document.getElementById('reject-call-button').textContent = "End"
                document.getElementById('accept-call-button').disabled = true
                document.getElementById('reject-call-button').disabled = false
                break;
            case "incoming":
                document.getElementById('call-notification-ui').style.display = 'block'
                document.getElementById('accept-call-button').textContent = this.TYPE === "inbound" ? "Answer" : "Dialing..."
                document.getElementById('reject-call-button').textContent = this.TYPE === "inbound" ? "Reject" : "Cancel"
                document.getElementById('accept-call-button').disabled = this.TYPE === "outbound"
                document.getElementById('reject-call-button').disabled = this.TYPE === "inbound"
                break;
            case "ended":
                document.getElementById('call-notification-ui').style.display = 'none'
                document.getElementById('accept-call-button').textContent = "Answer"
                document.getElementById('reject-call-button').textContent = "Reject"
                document.getElementById('accept-call-button').disabled = false
                document.getElementById('reject-call-button').disabled = true
                break;
        }
    }

    setUpListener() {
        const self = this;
        try {
            this.AWS_CONNECT.contact(function (contact) {
                const contactState = contact.getStatus().type;
                if (contactState === window.connect.ContactStatusType.INCOMING || contactState === 'connecting') {
                    console.log('Incoming call detected initially');
                    self.CURRENT_CONTACT = contact;
                    self.setupContactDetails(contact);
                    self.updateStatus("incoming")
                }
                if (contactState === "connected") {
                    self.setupContactDetails(contact);
                    self.updateStatus("ongoing")
                    self.CALL_START_TIME = new Date();
                }
                contact.onIncoming(function () {
                    console.log("Incoming call event detected!");
                });
                contact.onAccepted(function () {
                    console.log("Call accepted by agent!");
                    self.CALL_START_TIME = new Date();
                });
                contact.onConnected(function () {
                    console.log("Call connected!");
                    self.updateStatus("ongoing")
                    self.CALL_START_TIME = new Date();
                });
                contact.onEnded(function () {
                    console.log("Call ended.");
                    self.updateStatus("ended")
                });
            });
        } catch (e) {
            console.log('connect listener error: ', e);
        }
    }

    closeAll() {
        console.log('this.agent: ', this.AGENT)
        const contacts = this.AGENT.getContacts(this.AWS_CONNECT.ContactType.VOICE);
        console.log('contacts: ', contacts);
        // Loop through each contact and attempt to complete it
        contacts.forEach(function (contact) {
            contact.complete({
                success: function () {
                    console.log(`Successfully closed contact: ${contact.getContactId()}`);
                },
                failure: function (error) {
                    console.error(`Failed to close contact: ${contact.getContactId()}`, error);
                }
            });
        });
    }

    initiateOutboundCall(phoneNumber, cb) {
        if (this.AGENT) {
            const self = this;
            const endpoint = this.AWS_CONNECT.Endpoint.byPhoneNumber(phoneNumber);
            this.AGENT.connect(endpoint, {
                success: function () {
                    self.TYPE = "outbound";
                    console.log(`Outbound call to ${phoneNumber} initiated successfully.`);
                    if(cb)
                        cb();
                },
                failure: function (error) {
                    self.TYPE = "inbound";
                    console.error("Failed to initiate the outbound call: ", error);
                }
            });
        }
    }

    answerCall() {
        if (this.CURRENT_CONTACT) {
            document.getElementById('accept-call-button').disabled = true;
            this.CURRENT_CONTACT.accept({
                success: function () {
                    console.log('Call accepted successfully');
                },
                failure: function () {
                    console.log('Failed to accept the call');
                }
            });
        } else {
            const contacts = this.AGENT.getContacts(this.AWS_CONNECT.ContactType.VOICE);
            if (contacts && contacts[0]) {
                document.getElementById('accept-call-button').disabled = true;
                this.CALL_START_TIME = new Date();
                contacts[0].accept({
                    success: function () {
                        console.log('Call accepted successfully');
                        document.getElementById('accept-call-button').textContent = "Call ongoing..."
                        document.getElementById('reject-call-button').textContent = "End"
                    },
                    failure: function () {
                        console.log('Failed to accept the call');
                        document.getElementById('accept-call-button').disabled = false
                    }
                });
            } else {
                alert("no pending call??")
            }
        }

    }

    rejectCall() {
        const self = this;
        const contacts = this.AGENT.getContacts(this.AWS_CONNECT.ContactType.VOICE);
        contacts.forEach(function (contact) {
            const agentConnection = contact.getAgentConnection();
            if (agentConnection) {
                agentConnection.destroy({
                    success: function () {
                        console.log("Call successfully ended.");
                        contact.complete({
                            success: async function () {
                                const customerDetails = contact?.getInitialConnection()?.getAddress();
                                const name = customerDetails?.name
                                const number = customerDetails?.phoneNumber
                                const duration = self.calculateDuration()
                                await apiClient.post('/monday/add', {
                                    "customerName": name ?? "Unknown",
                                    "phoneNumber": number,
                                    "type": self.TYPE,
                                    "duration": duration,
                                    "contactId": contact.contactId,
                                    "date": dayjs(new Date()).format('MMMM D, YYYY hh:mm A'),
                                    "notes": localStorage.getItem('notes-value') || ''
                                });
                                if(self.CLEAR_NOTES)
                                    self.CLEAR_NOTES('');
                                console.log(`Successfully closed contact: ${contact.getContactId()}`);
                            },
                            failure: function (error) {
                                console.error(`Failed to close contact: ${contact.getContactId()}`, error);
                            }
                        });
                    },
                    failure: function () {
                        console.log("Failed to end the call.");
                    }
                });
            }
            if (contact && contact.getActiveInitialConnection().getType() === "agent") {
                const agentConnection = contact.getAgentConnection();
                if (agentConnection) {
                    agentConnection.destroy({
                        success: function () {
                            console.log("Call successfully ended.");
                        },
                        failure: function () {
                            console.log("Failed to end the call.");
                        }
                    });
                }
            } else {
                console.log("No active call found to end.");
            }
        });

    }

    calculateDuration() {
        if (!this.CALL_START_TIME)
            return "";
        const initiationDate = this.CALL_START_TIME;
        const disconnectDate = new Date();
        const diffMs = disconnectDate - initiationDate;

        const seconds = Math.floor((diffMs / 1000) % 60);
        const minutes = Math.floor((diffMs / (1000 * 60)) % 60);
        const hours = Math.floor((diffMs / (1000 * 60 * 60)) % 24);

        return `${hours} hours, ${minutes} minutes and ${seconds} seconds.`;
    }

    logout(){
        console.log('this.agent: ', this.AGENT)
        if(this.AGENT && this.AGENT.logout)
            this.AGENT.logout();
    }

}
