import React, { useState } from "react";
import { Button, TextField, Heading } from "monday-ui-react-core";
import "monday-ui-react-core/dist/main.css";
import apiClient from "../../helpers/axiosHelper";
import AWSLogo from "../../assets/images/amazon-connect.svg";

const Config = ({
                    setStatusBase,
                    awsConfig,
                    setAWSConfig,
                    initializeConnectWithQ,
                    initializing,
                    isFormValid,
                    loggedIn
                }) => {
    const [loading, setLoading] = useState(false);
    const status ={
        connect: 'IN-PROGRESS',
        lens: 'IN-PROGRESS',
        profile: 'IN-PROGRESS',
        ccp: 'IN-PROGRESS'
    };

    const handleChange = (value, name) => {
        setAWSConfig(prevConfig => ({
            ...prevConfig,
            [name]: value
        }));
    };

    const submitConfig = async () => {
        setLoading(true);

        setStatusBase({
            msg: `VALIDATION STATUS: CONNECT: ${status.connect}, LENS: ${status.lens}, PROFILE: ${status.profile}, CCP: ${status.ccp}`,
            key: Math.random(),
            severity: "info"
        });
        try {
            const response = await apiClient.post('/aws/add-token', awsConfig);
            const { success, data } = response;
            if (success) {
                const endpoints = [
                    { url: '/aws/check-connect-status', type: 'connect' },
                    { url: '/aws/check-lens-status', type: 'lens' },
                    { url: '/aws/check-profile-status', type: 'profile' },
                    { url: '/aws/check-ccp-status', type: 'ccp' }
                ];

                // Use a local variable to track status updates
                const localStatus = { ...status };

                const statusPromises = endpoints.map(endpoint =>
                    apiClient.get(endpoint.url)
                        .then(response => {
                            console.log('endpoint.type: ', endpoint.type, response);
                            localStatus[endpoint.type] = response.success ? 'PASSED' : 'FAILED';
                        })
                        .catch(() => {
                            localStatus[endpoint.type] = 'FAILED';
                        })
                        .finally(()=>{
                            let severity;
                            if (Object.values(localStatus).every(status => status === 'PASSED')) {
                                severity = 'success';
                            } else if (Object.values(localStatus).every(status => status === 'IN-PROGRESS')) {
                                severity = 'info';
                            } else if (Object.values(localStatus).includes('FAILED')) {
                                severity = 'error';
                            } else {
                                severity = 'info'; // Default to info if none of the above conditions are met
                            }
                            setStatusBase({
                                msg: `VALIDATION STATUS: CONNECT: ${localStatus.connect}, LENS: ${localStatus.lens}, PROFILE: ${localStatus.profile}, CCP: ${localStatus.ccp}`,
                                key: Math.random(),
                                severity: severity
                            });
                        })
                );

                await Promise.allSettled(statusPromises);

                setAWSConfig(data);
            }
        } catch (e) {
            setStatusBase({ msg: e.message, key: Math.random(), severity: "error" });
        }
        setLoading(false);
    };

    const fields = [
        { name: "region", title: "Region" },
        { name: "accessKeyId", title: "Access Key ID" },
        { name: "secretAccessKey", title: "Secret Access Key" },
        { name: "instanceId", title: "Connect Instance Id" },
        { name: "connectInstanceAlias", title: "Connect Instance Alias" },
        { name: "customerProfileDomain", title: "Customer Profile Domain" }
    ];

    return (
        <div className="Config center">
            <img src={AWSLogo} alt="" style={{ width: "25%", display: "flex", margin: "auto" }} />
            <br />
            <Heading type={Heading.types.h2} value="Please provide your AWS Configuration to Enable Monday App Connection" style={{ textAlign: "center" }} />
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                gap: '16px',
                width: '300px',
                margin: "auto"
            }}>
                {fields.map(field => (
                    <TextField
                        key={field.name}
                        name={field.name}
                        placeholder={field.title}
                        title={field.title}
                        value={awsConfig[field.name]}
                        onChange={(value) => handleChange(value, field.name)}
                        required
                        size={TextField.sizes.LARGE}
                    />
                ))}

                <Button
                    onClick={submitConfig}
                    disabled={loading || !isFormValid() || initializing || loggedIn}
                    loading={loading}
                    style={{ marginTop: '16px', fontSize: "25px" }}
                >
                    {loading ? 'Connecting...' : 'Validate'}
                </Button>
            </div>
        </div>
    );
};

export default Config;