import React from 'react';
import { Button, Box, Avatar, Typography } from '@mui/material';

function CallNotification({toggleNotes}) {
    return (
        <Box
            sx={{
                width: 500,
                p: 2,
                borderRadius: 2,
                bgcolor: '#2D2F36',
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between',
                boxShadow: '0 2px 10px rgba(0, 0, 0, 0.2)',
            }}
        >
            <Box display="flex" alignItems="center">
                <Avatar src="/path/to/avatar.jpg" id="Contact-Avatar" />
                <Box ml={2}>
                    <Typography variant="body2" color="#fff">
                        <span id="Contact-Name">Unknown</span>
                    </Typography>
                    <Typography variant="caption" color="#8c8c8c">
                        <span style={{fontSize: "xx-small"}} id="Contact-Number"></span>
                    </Typography>
                </Box>
            </Box>
            <Box display="flex" gap={1}>
            <Button
                    variant="contained"
                    sx={{ fontSize: '0.7rem', bgcolor: '#00854d', '&:hover': { bgcolor: '#00A045' } }}
                    id="accept-call-button"
                >
                    Accept
                </Button>
                <Button
                    variant="contained"
                    sx={{ fontSize: '0.7rem', bgcolor: '#D32F2F', '&:hover': { bgcolor: '#B71C1C' } }}
                    id="reject-call-button"
                >
                    Reject
                </Button>
                <Button
                    variant="contained"
                    sx={{ fontSize: '0.7rem', bgcolor: '#f5b328', '&:hover': { bgcolor: '#c28d1f' } }}
                    onClick={()=>{toggleNotes(true)}}
                >
                    Notes
                </Button>
            </Box>
        </Box>
    );
}

export default CallNotification;
